import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import countyZone from './data/countyZone.json';
import {
  USAGE_COMMERCIAL,
  USAGE_RESIDENTIAL,
  CLOSING_BUY,
  CLOSING_REFI,
  REFI_STRAIGHT,
  REFI_CEMA,
  RESIDENTIAL_TYPE,
  TOEPP_RESIDENTAL_TYPES,
} from './calcConstants';
import { setCalculatorInput } from '../Redux/sliceCalculatorInput';
import { RootState } from '../Redux/store';
import { validateInput } from './helpers/validateInput';
import { ModelRateInputData } from './rateInputData.model';

function RateCalculator() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<{
    purchase?: string
    loan?: string
    loanOld?: string
    county?: string
  }>({});
  const [showError, setShowError] = useState(false);

  const calculatorInput = useSelector((state: RootState) => state.calculatorInput);

  const [cities, setCities] = useState<string[]>([]);

  const handleFieldChange = (field: string, value: string | boolean) => {
    // console.log({ field, value });
    const newData: ModelRateInputData = { ...calculatorInput };
    if (field === 'county') {
      let newCities: string[] = [];
      countyZone.forEach((row) => {
        if (row.county === value && row.cities !== undefined) {
          newCities = row.cities;
        }
      });
      setCities(newCities);
      if (cities.length === 0) newData.city = '';
    }
    if (field === 'usageType' && value !== USAGE_RESIDENTIAL) newData.toepp = false;
    if (field === 'residentialType' && !TOEPP_RESIDENTAL_TYPES.includes(value as string)) newData.toepp = false;
    newData[field] = value;
    dispatch(setCalculatorInput(newData));
  };

  const handleSubmitClick = async () => {
    const { isValid, errors } = validateInput(calculatorInput);
    const hash = md5(JSON.stringify(calculatorInput));
    if (isValid) {
      await axios.post(
        `${process.env.REACT_APP_SLS_URL}/saveRateInput`,
        { data: calculatorInput },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'kZtxLjtuFI1PJHXIK3YbBaYNnU6rGUFF4cEW2VXa',
          },
        },
      );
      // console.log(results);
      navigate(`/report/${hash}`);
      // }).catch((err) => console.error(err));
    } else {
      // console.log({ isValid, errors });
      setErrorMsg(errors);
      setShowError(!isValid);
    }
  };
  return (
    <div className="rate-calculator">
      <form>
        <label>Closing Type</label>
        <select
          value={calculatorInput.closingType}
          onChange={(e) => handleFieldChange('closingType', e.target.value)}
        >
          <option value={CLOSING_BUY}>{CLOSING_BUY}</option>
          <option value={CLOSING_REFI}>{CLOSING_REFI}</option>
        </select>

        <label>Usage Type</label>
        <select
          value={calculatorInput.usageType}
          onChange={(e) => handleFieldChange('usageType', e.target.value)}
        >
          <option value={USAGE_COMMERCIAL}>{USAGE_COMMERCIAL}</option>
          <option value={USAGE_RESIDENTIAL}>{USAGE_RESIDENTIAL}</option>
        </select>
        {calculatorInput.usageType === USAGE_RESIDENTIAL ? (
          <div>
            <label>Residential Property Type</label>
            <select
              value={calculatorInput.residentialType}
              onChange={(e) => handleFieldChange('residentialType', e.target.value)}
            >
              {Object.keys(RESIDENTIAL_TYPE).map((key) => (
                <option value={key} key={key}>{RESIDENTIAL_TYPE[key]}</option>
              ))}
            </select>
          </div>
        ) : null}
        {calculatorInput.usageType === USAGE_RESIDENTIAL
        && TOEPP_RESIDENTAL_TYPES.includes(calculatorInput.residentialType) ? (
          <div className="checkbox-group">
            <input
              type="checkbox"
              checked={calculatorInput.toepp}
              onChange={() => handleFieldChange('toepp', !calculatorInput.toepp)}
              id="toepp"
            />
            <label htmlFor="toepp">TIRSA Owner’s Extended Protection Policy (TOEPP)</label>
          </div>
          ) : null}
        {calculatorInput.closingType === CLOSING_REFI ? (
          <div className="init_hide refi_div">
            <label>Refinance Type</label>
            <select
              value={calculatorInput.refiType}
              onChange={(e) => handleFieldChange('refiType', e.target.value)}
            >
              <option value={REFI_STRAIGHT}>{REFI_STRAIGHT}</option>
              <option value={REFI_CEMA}>{REFI_CEMA}</option>
            </select>
          </div>
        ) : (
          <div className="purchase_div">
            <label>Purchase Price</label>
            <input
              type="text"
              value={calculatorInput.purchase}
              onChange={(e) => handleFieldChange(
                'purchase',
                e.target.value,
              )}
            />
            {errorMsg.purchase !== undefined ? (
              <div className="error">
                {errorMsg.purchase}
              </div>
            ) : null}
          </div>
        )}

        <div className="loan_div">
          <label>{calculatorInput.closingType === CLOSING_REFI && calculatorInput.refiType === REFI_CEMA ? 'New Money' : 'Loan Amount'}</label>
          <input
            type="text"
            value={calculatorInput.loan}
            onChange={(e) => handleFieldChange(
              'loan',
              e.target.value,
            )}
          />
          {errorMsg.loan !== undefined ? (
            <div className="error">
              {errorMsg.loan}
            </div>
          ) : null}
        </div>

        {calculatorInput.refiType === REFI_CEMA
                && calculatorInput.closingType === CLOSING_REFI ? (
                  <div className="init_hide cema_div">
                    <label>Principal Outstanding</label>
                    <input
                      type="text"
                      value={calculatorInput.loanOld}
                      onChange={(e) => handleFieldChange(
                        'loanOld',
                        e.target.value,
                      )}
                    />
                    {errorMsg.loanOld !== undefined ? (
                      <div className="error">
                        {errorMsg.loanOld}
                      </div>
                    ) : null}
                  </div>
          ) : null}

        <label>
          Number of
          {calculatorInput.closingType === CLOSING_REFI ? ' Owners' : ' Buyers'}
        </label>
        <input
          type="text"
          value={calculatorInput.qtyBuyers}
          onChange={(e) => handleFieldChange(
            'qtyBuyers',
            e.target.value,
          )}
        />

        {calculatorInput.closingType === CLOSING_BUY ? (
          <>
            <label>Number of Sellers</label>
            <input
              type="text"
              value={calculatorInput.qtySellers}
              onChange={(e) => handleFieldChange(
                'qtySellers',
                e.target.value,
              )}
            />
          </>
        ) : null}

        <label>County</label>
        <select
          value={calculatorInput.county}
          onChange={(e) => handleFieldChange('county', e.target.value)}
        >
          <option value="">-</option>
          {countyZone.map((county) => (
            <option key={county.county} value={county.county}>{county.county}</option>
          ))}
        </select>
        {errorMsg.county !== undefined ? (
          <div className="error">
            {errorMsg.county}
          </div>
        ) : null}

        {cities.length > 0 ? (
          <div className="city_div init_hide">
            <label>City</label>
            <select
              value={calculatorInput.city}
              onChange={(e) => handleFieldChange('city', e.target.value)}
            >
              <option value="">-</option>
              {cities.map((city) => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
          </div>
        ) : null}
        {calculatorInput.county === 'Suffolk' && calculatorInput.city !== '' ? (
          <div className="improved_div init_hide">
            <label>Improved property</label>
            <input
              type="checkbox"
              checked={calculatorInput.improved}
              onChange={() => handleFieldChange('improved', !calculatorInput.improved)}
            />
          </div>
        ) : null}
        {showError ? (
          <div className="error">
            Please fix errors above.
          </div>
        ) : null}
        <div className="calculate">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmitClick}
          >
            Calculate
          </button>
        </div>
      </form>
    </div>
  );
}
export default RateCalculator;

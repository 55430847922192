/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { setSplash } from '../../Redux/sliceSplash';

export default function Splash() {
  const showSplash = useSelector((state: RootState) => state.splash.show);
  const dispatch = useDispatch();

  const vidRef = useRef(null);
  const [playIcon, setPlayIcon] = useState('fa-pause');
  const [mute, setMute] = useState(true);
  const toggleMute = () => setMute(!mute);
  const playVid = () => {
    if (vidRef.current) {
      if ((vidRef.current as HTMLVideoElement).paused) {
        (vidRef.current as HTMLVideoElement).play();
        setPlayIcon('fa-pause');
      } else {
        (vidRef.current as HTMLVideoElement).pause();
        setPlayIcon('fa-play');
      }
    }
  };
  const handleClose = () => {
    if (vidRef.current) {
      (vidRef.current as HTMLVideoElement).pause();
    }
    dispatch(setSplash({ show: false }));
  };
  return (
    <Modal size="lg" animation={false} show={showSplash} className="splash" onHide={handleClose}>
      <Modal.Body>
        <div className="splash-actions">
          <button type="button" onClick={() => toggleMute()} className="as-text music-toggle">
            <span className={`fas fa-fw fa-volume-${mute ? 'xmark' : 'high'}`} />
          </button>
          <button type="button" onClick={() => playVid()} className="as-text music-toggle">
            <span className={`fas fa-fw ${playIcon}`} />
          </button>
          <button type="button" onClick={() => dispatch(setSplash({ show: false }))} className="as-text music-toggle">
            <span className="fas fa-fw fa-xmark" />
          </button>
        </div>
        <div className="splash-content">
          <video autoPlay playsInline muted={mute} ref={vidRef} onEnded={() => setPlayIcon('fa-play')}>
            <source src="https://lla-assets.s3.amazonaws.com/lla-nationwide.mp4" type="video/mp4" />
          </video>
          <div className="call-to-action">
            <Button type="button" onClick={() => handleClose()}>
              Call Us Now 718.281.0505
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { useEffect } from 'react';
import { useLocation } from 'react-router';

declare global {
  interface Window {
    _hsq: any;
  }
}

export default function Hubspot() {
  const { pathname } = useLocation();
  useEffect(() => {
    const script = document.createElement('script');
    if (window.location.hostname !== 'localhost') {
      script.src = '//js.hs-scripts.com/45416926.js';
      script.async = true; // Load asynchronously
      script.onload = () => {
        console.log('Hubspot loaded');
      };
      document.body.appendChild(script);
      // eslint-disable-next-line no-multi-assign, @typescript-eslint/naming-convention
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', pathname]);
      _hsq.push(['trackPageView']);
    }
    return () => {
      if (window.location.hostname !== 'localhost') {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (!window._hsq) {
      return;
    }
    if (window.location.hostname !== 'localhost') {
      // eslint-disable-next-line no-multi-assign, @typescript-eslint/naming-convention
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', pathname]);
      _hsq.push(['trackPageView']);
    }
  }, [pathname]);
  return <div />;
}

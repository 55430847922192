export const USAGE_COMMERCIAL = 'Commercial';
export const USAGE_RESIDENTIAL = 'Residential';
export const CLOSING_BUY = 'Purchase';
export const CLOSING_REFI = 'Refinance';

export const REFI_STRAIGHT = 'Straight';
export const REFI_CEMA = 'CEMA';

export const FAMILY1 = '1 family';
export const FAMILY2 = '2 family';
export const FAMILY3 = '3 family';
export const FAMILY4 = '4 family';
export const FAMILY5 = '5 family';
export const FAMILY6 = '6 family';
export const FAMILY7 = '7 family';
export const COOP = 'Co-Op';
export const CONDO = 'Condo';
export const SPONSOR_CONDO = 'Sponsor Condo';
export const RESIDENTIAL_TYPE: { [key: string]: string } = {
  [FAMILY1]: '1 Family',
  [FAMILY2]: '2 Family',
  [FAMILY3]: '3 Family',
  [FAMILY4]: '4 Family',
  [FAMILY5]: '5 Family',
  [FAMILY6]: '6 Family',
  [FAMILY7]: '7 or more Family',
  // [COOP]: COOP,
  [CONDO]: CONDO,
  [SPONSOR_CONDO]: SPONSOR_CONDO,
};
export const TOEPP_RESIDENTAL_TYPES = [
  FAMILY1,
  FAMILY2,
  FAMILY3,
  FAMILY4,
  CONDO,
  SPONSOR_CONDO,
];
export const MANSION_TYPE = [
  FAMILY1,
  FAMILY2,
  FAMILY3,
  CONDO,
  SPONSOR_CONDO,
];

export const NYC_COUNTIES = ['Bronx', 'Kings', 'New York', 'Queens', 'Richmond'];

export const USER_TYPE_PRO = 'pro';
export const USER_TYPE_RET = 'ret';

export const RECAPTCHA_SITE_KEY = '6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy'; // from usebasin
